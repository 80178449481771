/* eslint-disable */
import React, { Component } from 'react';
import { List } from 'immutable/dist/immutable';
import MapButton from '../containers/MapButton';
import ReactDOM from 'react-dom/lib/ReactDOM';
import { Provider } from 'react-redux/dist/react-redux';
import store from '../store';

const imageUrl = 'img/marker_agence2.png';
var markers = [];
class MapPage extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.map = new google.maps.Map(this.refs.map, {
      center: new google.maps.LatLng(this.props.currentLat,
       this.props.currentLong),
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      mapTypeControl: false,
    });
    var mapStyles = [{featureType: "administrative.country",stylers: [{ visibility: "off" }]}];
    var mapType = new google.maps.StyledMapType(mapStyles ,{name: "Maroc"});
    this.map.mapTypes.set('maroc', mapType );
    this.map.setMapTypeId('maroc');

    for (var i = 0; i < this.props.agencesList.size; i++) {
      var agency = this.props.agencesList.get(i);
      var latLng = new google.maps.LatLng(agency.get('latitude'),
           agency.get('longitude'));
      var marker = new google.maps.Marker({
            position: latLng,
            icon: new google.maps.MarkerImage(imageUrl, new google.maps.Size(40, 60)),
            title: 'AGENCE '+agency.get('label'),
          });
      var infowindow = new google.maps.InfoWindow({
            content: this.props.agencesList.get(i).get('adresse'),
            maxWidth: 330});

      var infos = '<b>Agence '+ agency.get('label')+'</b><br>'+agency.get('adresse')+'<br>'+agency.get('telephone');

      google.maps.event.addListener(marker, 'mouseover', (function (infos, marker, i) {
        var div = document.createElement('div');
        ReactDOM.render( <Provider store={store}>
                           <MapButton agence= {agency} />
                          </Provider>, div );
          return function () {
          infowindow.setContent(div);
          infowindow.open(this.map, marker);
          }
      })(infos, marker, i));
      markers.push(marker);
    }
    var styles = [[{
      url: 'img/marker_group.png',
      height: 70,
      width: 40,
      anchor: [16, 0],
      textColor: '#fff',
      textSize: 14
    }]];
        var markerCluster = new MarkerClusterer(this.map, markers, {
          maxZoom: 12,
          gridSize: 10,
        });

        var input = document.getElementById('pac-input');
        var searchBox = new google.maps.places.SearchBox(input);
      // Bias the SearchBox results towards current map's viewport.
      let self = this ;
      this.map.addListener('bounds_changed', function() {
        searchBox.setBounds(self.map.getBounds());
      });

     searchBox.addListener('places_changed', function() {
        var places = searchBox.getPlaces();

        if (places.length == 0) {
          return;
        }


        // For each place, get the icon, name and location.
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function(place) {
          if (!place.geometry) {
            return;
          }

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        self.map.fitBounds(bounds);
      });
  }
  componentDidUpdate(prevProps, prevState) {
  if (prevProps.currentLat !== this.props.currentLat) {
    this.map.setZoom(12);
    this.map.setCenter(new google.maps.LatLng(this.props.currentLat,
     this.props.currentLong));
  }
  if (prevProps.currentAgence !== this.props.currentAgence && prevProps.currentLat === this.props.currentLat ) {
    setTimeout(function () {
      this.map.setZoom(15);
      // this.map.panTo(new google.maps.LatLng(this.props.currentAgence.get('latitude'),
      //  this.props.currentAgence.get('longitude')));

    var marker = new google.maps.Marker({
          position: new google.maps.LatLng(this.props.currentAgence.get('latitude'),
           this.props.currentAgence.get('longitude')),
          icon: new google.maps.MarkerImage(imageUrl, new google.maps.Size(40, 60)),
          title: 'AGENCE '+this.props.currentAgence.get('label'),
        });
        var infowindow = new google.maps.InfoWindow({
              content: 'init',
              maxWidth: 340});
        var div = document.createElement('div');
        ReactDOM.render( <Provider store={store}>
                           <MapButton agence= {this.props.currentAgence} />
                          </Provider>, div );
          infowindow.setContent(div);
          infowindow.open(this.map, marker);
      google.maps.event.addListener(marker, 'mouseover', (function ( marker) {
              return function () {
              infowindow.setContent(div);
              infowindow.open(this.map, marker);
              }
          })(marker));
        markers.push(marker);
        var markerCluster = new MarkerClusterer(this.map, markers, {
          maxZoom: 12,
          gridSize: 10,
        });
  }.bind(this), 500);

}
}
  render() {
    const mapStyle = {
      margin: 'auto',
      height: 362,
    };
    return (
      <div className="map_container">
        <div ref="map" className="mapStyle" style={mapStyle}>I should be a map!</div>
      </div>
    );
  }
}
MapPage.propTypes = {
  agencesList: React.PropTypes.instanceOf(List).isRequired,
  currentLat: React.PropTypes.string,
  currentLong: React.PropTypes.string,
  currentAgence: React.PropTypes.object,
};
MapPage.defaultProps = {
  currentLat: '33.5860467750001',
  currentLong: '-7.64699039699997',
};
export default MapPage;
